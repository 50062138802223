<template>
  <div class="account-list">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="季度收益记录" />
    <!-- 搜索框 -->
    <ml-tip :showHeader="false" style="border: 0">
      <ml-form inline labelWidth="100px" :model="searchData" ref="searchDataRef">
        <!-- 帐号 -->
        <ml-select
          prop="companyId"
          placeholder="请选择阿米巴"
          :options="unitOptions"
          label="阿米巴:"
          keyName="companyId"
          labelName="companyName"
          valueName="companyId"
          v-model="searchData.companyId"
          style="margin-bottom: 0"
          @change="searchFn"
        />
        <!-- 日期 -->
        <ml-date-picker
          prop="date"
          startPlaceholder="开始日期"
          endPlaceholder="结束日期"
          label="修改时间:"
          type="datetimerange"
          v-model="searchData.date"
          :style="{ 'margin-bottom': 0, 'margin-right': '40px' }"
        />
        <!-- 搜索按钮 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="搜索"
          submitIcon="el-icon-search"
          cancelText="重置"
          cancelIcon="el-icon-refresh"
          cancelType="danger"
          @click-cancel="resetSearchData"
          @click-submit="searchFn"
        />
      </ml-form>
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%" :height="tabberHeight(319)">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="阿米巴"
          prop="companyName"
          min-width="10%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="人数"
          prop="userCount"
          min-width="10%"
        />
        <!-- <el-table-column
          show-overflow-tooltip
          align="center"
          label="合同时间（年）"
          prop="year"
          min-width="10%"
        /> -->
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="合同总金额（万元）"
          prop="contractAmount"
          min-width="10%"
        />
        <el-table-column align="center" label="年份" width="80">
          <template #default="{row}">
            {{ new Date(row.createTime).getFullYear() }}
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="季度"
          prop="quarter"
          min-width="10%"
        />
        <el-table-column align="center" label="收入（万元）" prop="incomeAmount" min-width="13%" />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="成本（万元）"
          prop="costAmount"
          min-width="10%"
        />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="利润（万元）"
          prop="profitAmount"
          min-width="12%"
        />

        <el-table-column align="center" label="修改时间" prop="updateTime" min-width="15%" />
        <el-table-column align="center" label="操作" width="120">
          <template #default="scope">
            <el-button type="danger" icon="el-icon-delete" @click="clickDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>

    <!-- 删除账户提示框 -->
    <ml-dialog
      ref="deleteUserDialogRef"
      :content="deleteUserData.content"
      @click-submit="submitDeleteUser"
    />
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch } from 'vue'
import {
  tabberHeight,
  getTabberData,
  getTreeString,
  getProjectsString,
  searchParams,
  formetData
} from '@/utils'
import { useStore } from 'vuex'

export default {
  name: 'QuarterlyEarnings',
  setup() {
    const { commit, dispatch } = useStore()

    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      companyId: '',
      date: []
    })
    // 单位配置
    const unitOptions = ref([])
    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      tabberData.page === 1 ? getUserTabberData() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getUserTabberData() : (tabberData.page = 1)
    }
    /* 结束 搜索 **********************************/

    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: 1,
      size: 10
    })

    // 获取m账户列表
    const getUserTabberData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData, ['date'])
      if (searchData.date && searchData.date.length > 0) {
        searchDataParams.createTimeStart = formetData(searchData.date[0])
        searchDataParams.createTimeEnd = formetData(searchData.date[1])
      }
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetQuarterlyEarningRecords',
        params,
        searchDataParams
      )
      tabberData.data = data || []
      tabberData.total = total || 0
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      getUserTabberData()
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }

    // 删除账户
    const deleteUserDialogRef = ref()
    const deleteUserData = reactive({
      content: '确定删除该季度收益记录？'
    })
    const clickDelete = row => {
      deleteUserData.recordId = row.recordId
      deleteUserDialogRef.value.showDialog = true
    }
    // 确定删除
    const submitDeleteUser = () => {
      const { recordId } = deleteUserData
      dispatch('fetchQuarterlyEarningRecord', recordId).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
          tabberData.page === 1 ? getUserTabberData() : (tabberData.page = 1)
          deleteUserDialogRef.value.showDialog = false
        }
      })
    }

    /* 结束 数据列表 **********************************/
    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {})
      unitOptions.value = data || []
      getUserTabberData()
    })
    return {
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      clickDelete,
      deleteUserData,
      deleteUserDialogRef,
      submitDeleteUser,
      getTreeString,
      getProjectsString,
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      unitOptions
    }
  }
}
</script>

<style lang="scss" scoped>
.account-list {
  @extend %params-global;
  .el-input__inner {
    width: 170px;
  }
}
</style>
